<template>
  <div />
</template>

<script>

export default {
  name: 'List',

  setup() {
    return {}
  },
}
</script>
